import React, {useEffect, useRef, useState} from 'react';
import Header from "../components/header";
import {useLocation, Link} from "react-router-dom";

const CheckPaymentPage = () => {
  const _ping = useRef();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const ref = query.get('ref')
  const status = query.get('status')

  useEffect(() => {
    if (status === 'success') {
      check()
      _ping.current = setTimeout(check, 3000);
    }
    return () => {
      stop()
    }
  }, []);

  const stop = () => {
    clearTimeout(_ping.current);
  }

  const check = async () => {
    stop()
    if (ref) {
      try {
        const response = await fetch(`https://api.futuris.school/subscriptionCheck?ref=${ref}`);
        const data = await response.json();
        if (data.token) {
          window.location.href = `https://admin.futuris.school/as/${data.token}`;
        }
        if (data.status === 'pending') {
          _ping.current = setTimeout(check, 3000);
        }
      } catch (e) {
        stop();
      }

    }
  }

  return (
    <>
      <Header/>
      <div className='container'>
        <main>
          <h3 className='title'>Payment</h3>
          {status === 'success' && <div className='text-success'>Payment was successful</div>}
          {status === 'failed' && <>
            <div className='text-danger'>Payment failed</div>
            <Link to='/' className='btn btn-primary welcome-btn'>Home</Link>
          </>
          }
        </main>
      </div>
    </>
  );
};

export default CheckPaymentPage;