import React from 'react';
import './roles.css';
import crown from '../../assets/images/icons/crown.svg';
import cap from '../../assets/images/icons/cap.svg';
import glasses from '../../assets/images/icons/glasses.svg';
import settings from '../../assets/images/icons/settings.svg';
import youtube from '../../assets/images/icons/youtube.svg';
import RoleItem from "./RoleItem";

const data = [
  {
    icon: crown,
    title: 'Admin',
    text: 'Has a full access to the platform, can add students, create courses and implement any changes in teaching process'
  },
  {
    icon: cap,
    title: 'Student',
    text: 'Has an access to the student’s panel, can communicate with this mentors and send hometasks for check'
  },
  {icon: glasses, title: 'Mentor', text: 'Checks hometasks, communicates with students'},
  {icon: settings, title: 'Editor', text: 'Can make changes in courses and classes'},
  {icon: youtube, title: 'Blogger', text: 'Can record webinars, create educational materials'},
]

const Roles = () => {
  return (
    <section className='roles'>
      <div className="row">
        <div className="col-12 col-md-8 pe-0"><h3 className='title'>A variety of roles for effective learning process</h3></div>
        <div className="row roles_list">
          {data.map((item, i) => <RoleItem key={i} {...item} />)}
        </div>
      </div>
    </section>
  );
}

export default Roles;