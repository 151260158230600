import React from 'react';
import arrow from '../../assets/images/icons/arrow.svg';
import {ReactSVG} from "react-svg";

const CarouselArrowBtn = ({type, onClick}) => {

  return (
    <div className={`carousel-arrow-wrap ${type || 'right'}`}>
      <button type="button" className={`carousel-arrow`} onClick={onClick}>
        <ReactSVG src={arrow}/>
      </button>
    </div>
  );
};

export default CarouselArrowBtn;
