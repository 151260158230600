import React from 'react';
import {ReactSVG} from 'react-svg'
import check from '../../assets/images/icons/check.svg';

const CreatorsItem = ({icon, title}) => {
  return (
      <div className='creator_item'>
        <div className='creator_item-icon'>
          <ReactSVG src={icon}/>
        </div>
        <div className='bold-20 ms-4 text-uppercase flex-grow-1'>{title}</div>
        <ReactSVG src={check}/>
      </div>
  );
}

export default CreatorsItem;