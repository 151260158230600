import React from 'react';
import './welcome.css';
import face from '../../assets/images/applications/face.png';
import book from '../../assets/images/applications/book.png';
import {scrollToPlan} from "../../modules/utils";

const Welcome = ({onFreeClick}) => {
  return (
    <section className='welcome'>
      <div className='title'>
        <img src={face} alt="face" className='welcome-title_icon'/>
        <span>Your best <br/>course </span>
        <img src={book} alt="book" className='welcome-title_icon'/>
        <span> platform</span>
      </div>
      <h4 className='text-20 muted welcome-descr'>
        Are you ready to turn your knowledge into money? Start creating successful courses with all-in-one course platform Edcraze!
      </h4>
      <div onClick={scrollToPlan} className='btn btn-primary welcome-btn'>Choose Plan</div>
    </section>
  );
}

export default Welcome;

