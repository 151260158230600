import React from "react";
import {Switch, Route, BrowserRouter, Redirect} from "react-router-dom";
import HomePage from "./pages/HomePage";
import CheckPaymentPage from "./pages/CheckPaymentPage";


function App() {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={(props) => <HomePage/>}/>
        <Route exact path="/payment-status" render={() => <CheckPaymentPage/>}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
