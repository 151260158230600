import React from 'react';
import './footer.css';
import scientist from "../../assets/images/applications/scientist.png";
import cactus from "../../assets/images/applications/cactus.png";
import {scrollToPlan} from "../../modules/utils";

const Footer = ({onFreeClick}) => {
  return (
    <footer>
      <img src={cactus} alt="cactur" className='footer-cactus'/>
      <div className='title'>
        <span>Ready </span> <img src={scientist} alt="scientist" className='footer-title_icon'/> <span> to go?</span>
      </div>
      <div onClick={scrollToPlan} className='btn btn-primary footer-btn'>Start creating courses now!</div>
    </footer>
  );
}

export default Footer;