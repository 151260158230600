import React, {useCallback, useEffect, useState} from 'react';
import Welcome from "../components/welcome";
import MadeSimple from "../components/madeSimple";
import Slider from "../components/slider";
import Roles from "../components/roles";
import Plan from "../components/plan";
import Creators from "../components/creators";
import Footer from "../components/footer";
import Cookie from "../components/cookie";
import Header from "../components/header";
import EmailModal from "../components/EmailModal";
import {Modal} from "react-responsive-modal";
import Policy from "../components/Policy";
import Terms from "../components/Terms";
import TermsTeacher from "../components/TermsTeacher";


const dataTerms = {
  privacy: <Policy/>,
  terms: <Terms/>,
  teacher: <TermsTeacher/>,
}

const HomePage = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [openTerms, setOpenTerms] = useState('');
  const handleOpenModal = useCallback(() => {
    setEmailModalVisible(true)
  }, []);
  const handleCloseModal = useCallback(() => setEmailModalVisible(false), []);


  useEffect(() => {
    const terms = params.get('terms')
    if (terms) {
      window.history.replaceState(null, null, '/');
      onOpenModalTerms(terms)()

    }
  }, []);

  const onOpenModalTerms = (type) => () => {
    setOpenTerms(type);
    setTimeout(() => {
      const modalElArr = document.querySelectorAll('.react-responsive-modal-container');
      modalElArr.forEach(item => {
        item.scrollTo(0, 0)
      })
    }, 100);
  }
  const onCloseModalTerms = () => setOpenTerms('');
  return (
    <>
      <Header onFreeClick={handleOpenModal}/>
      <div className='container'>
        <main>
      <Welcome onFreeClick={handleOpenModal}/>
      <div className="separator"></div>
      <MadeSimple/>
      <div className="separator"></div>
      <Slider/>
      <div className="separator"></div>
      <Roles/>
      <div className="separator"></div>
      <Plan onClick={setEmailModalVisible}/>
      <div className="separator"></div>
      <Creators/>
      <div className="separator"></div>
      <Footer onFreeClick={handleOpenModal}/>
      <div className='d-flex align-items-center align-items-xl-end flex-column gap-3 flex-xl-row justify-content-between mt-4'>
        <p className='mt-3'><b>Silda Group LTD</b> <br/>
          85 Great Portland Street, First Floor, London, United Kingdom, W1W 7LT</p>
        <div className='d-flex justify-content-center'>
          <a
            href='https://docs.google.com/document/d/1UhHdNYj5CVBF6WEHoosr-fsnyMG4-g4cnnzTHnolcw4/edit?usp=sharing'
            target='_blank'
            className='terms-link' >Privacy Policy</a>
          <span className='px-3'>|</span>
          <a href='https://docs.google.com/document/d/1M7_9ApwsL3Vm6e5jX_k__qShFe0zINMe9sFFm6lzhVs/edit?usp=sharing' target='_blank' className='terms-link' >Terms of Service</a>
          <span className='px-3'>|</span>
          <a href='https://docs.google.com/document/d/1OXX-vXKzJ7kGPgf210jOfqYDQpi4yrlCeiV8LHFz05E/edit?usp=sharing' target='_blank' className='terms-link' >Teacher Terms</a>

        </div>
      </div>

      <Cookie/>
        </main>
      </div>
      <EmailModal onClose={handleCloseModal} visible={emailModalVisible} onOpenModalTerms={onOpenModalTerms}/>
      {Boolean(openTerms)
        ?
        <Modal open onClose={onCloseModalTerms} center showCloseIcon={false}>
          {dataTerms[openTerms] || null}
        </Modal>
        :
        null
      }

    </>
  );
};

export default HomePage;