import React from 'react';

const PlanItem = ({item, onClick}) => {
  return (
    <div className='col-12 col-md-4'>
      <div className='plan_item' onClick={onClick}>
        {item.recommended && <div className="plan_item_recommended">Recommended</div>}
        <div className='align-self-start'>{item.period}</div>
        <div className='d-flex justify-content-between align-items-end mt-3 mb-4 pb-2 w-100'>
          <div className='bold-20'>${item.amount}</div>
          <div>${item.price}/month</div>
        </div>
        <button className='btn btn-outline w-100 btn-lg' type='button'>
          Add
        </button>
      </div>
    </div>
  );
}

export default PlanItem;