import React, {useEffect, useState} from 'react';
import {Modal} from "react-responsive-modal";

const fullCompanyName = 'Silda Group LTD';
const companyName = 'Silda Group LTD';

const supportLinks = [
  {
    title: 'For Internet Explorer: ',
    url: 'http://windows.microsoft.com/en-us/windows7/how-to-manage-cookies-in-internet-explorer-9'
  },
  {title: 'For Chrome: ', url: 'https://support.google.com/chrome/answer/95647?hl=en'},
  {title: 'For Safari: ', url: 'http://support.apple.com/kb/HT1677?viewlocale=en_US'},
  {title: 'For Firefox: ', url: 'https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored'},
  {title: 'For Opera: ', url: 'http://help.opera.com/Linux/10.60/en/cookies.html'},
  {title: 'For Android: ', url: 'http://support.google.com/android/?hl=en'},
  {
    title: 'For Blackberry: ',
    url: 'https://help.blackberry.com/en/blackberry-classic/10.3.2/help/mwa1334238823957.html'
  },
  {
    title: 'For Windows Phone: ',
    url: 'http://www.windowsphone.com/en-US/how-to/wp7/web/changing-privacy-and-other-browser-settings'
  },
]

const Cookie = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [cookiesPolicyModal, setCookiesPolicyModal] = useState(false);
  useEffect(() => {
    const cookie = localStorage.getItem('cookie');
    if (!cookie) {
      localStorage.setItem('cookie', '1');
      setModalVisible(true);
    }
  }, []);

  const onCloseModal = () => setModalVisible(false);
  const onCloseCookiesPolicyModal = () => {
    setCookiesPolicyModal(false);
    setModalVisible(true)
  }

  return (
    <>
      <Modal
        closeOnOverlayClick
        closeOnEsc
        open={modalVisible}
        on
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        animationDuration={0}
        classNames={{root: 'cookie-modal'}}
      >
        <h4 className='title-32'>Your privacy matters</h4>
        <hr/>
        <p>We use own and third-party cookies to generate statistics on the use of the website in order to identify
          errors and improve the content and configuration of the website.</p>
        <p>We also use our own and third-party cookies to remember certain options you have chosen and to show you
          advertising related to your preferences, based on a profile drawn up from your browsing habits.</p>
        <hr/>
        <button type='button' className="btn btn-primary w-100" onClick={onCloseModal}>ACCEPT</button>
        <div className='d-flex mt-3'>
          <button type='button' className="btn btn-muted w-100 me-3" onClick={onCloseModal}>DECLINE</button>
          <button type='button' className="btn btn-outline w-100 ms-3"
                  onClick={() => setCookiesPolicyModal(true)}>COOKIES POLICY
          </button>
        </div>
      </Modal>

      {/**/}
      <Modal
        closeOnOverlayClick
        closeOnEsc
        open={cookiesPolicyModal}
        on
        onClose={onCloseCookiesPolicyModal}
        center
        showCloseIcon={false}
        animationDuration={0}
        classNames={{root: 'cookie-modal'}}>
        {/*<h5 className='fst-italic'>Last modified March, 2022</h5>*/}
        <h4 className='bold-20 mt-2'>Cookies Policy</h4>
        <hr/>
        <p className='mb-3'>{fullCompanyName} (Hereinafter, “{companyName}”) uses cookies for the operation of its
          website.</p>
        <p>Cookies are text files that contain small amounts of information that are downloaded in your computer or
          mobile device when you visit a website. Cookies are sent back to the original website at each subsequent
          visit, or to another website that recognizes them. Cookies are useful because they allow a website to
          recognize the user’s device. Cookies can perform different functions such as allowing you to navigate between
          pages efficiently, remembering your preferences, and generally improving user navigation.</p>
        <p>As for the types of cookies, these are classified according to the entity that manages them. Thus, we have
          our own cookies and those owned by third parties, according to the term that remain activated (session or
          persistent), according to its purpose (technical, personalization, analysis, advertising and behavioral
          advertising). </p>
        <p>We inform you about the types of cookies contained in this website and its purpose:</p>
        <table>
          <thead>
          <tr>
            <th>NAME</th>
            <th>TECHNIC AL NAME</th>
            <th>PURPOSE</th>
            <th>INFORMATION ON COLLECTED</th>
            <th>PERMANENCE TERM</th>
            <th>ENTITY WHO MANAGES (OWN OR THIRD PARTY)</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Google Analytics</td>
            <td>_ga</td>
            <td>Analysis</td>
            <td>User identificator</td>
            <td>Persistents(2 year)</td>
            <td>Google Analytics</td>
          </tr>
          <tr>
            <td>Google Analytics</td>
            <td>_gat</td>
            <td>Limitate the numbers of requests made doubleclick</td>
            <td>None</td>
            <td>Persistents(10 minutes)</td>
            <td>Google Analytics</td>
          </tr>
          </tbody>
        </table>
        <p>{companyName} uses Google Analytics cookies, which configuration is predetermined by the service offered by Google.
          We suggest you to consult the Google Analytics privacy page <a
            href="https://support.google.com/analytics/answer/6004245"
            target='_blank'>https://support.google.com/analytics/answer/6004245</a> for more information about the
          cookies you use and how to disable them (we understand that {companyName} is not responsible for the content or
          veracity of third-party websites).</p>
        <h5>Deactivating cookies</h5>
        <p>The user can freely decide on the implantation or not in his hard disk of our cookies as well as his
          elimination, following the procedure established in the help section of his navigator. The user can also
          configure their browser to accept or reject by default all cookies or to receive an on-screen warning of the
          reception of each cookie and decide at that time whether to implement or not on its hard drive.</p>
        <p>Links to device manufacturers and browsers for the management or deactivation of cookies in your browser:</p>
        <div>
          {supportLinks.map((item, i) => (
            <div key={i}>
              <p>{item.title}</p>
              <a href={item.url} target='_bl'>{item.url}</a>
            </div>
          ))}

        </div>
        <p>In the event that the user sets his browser to reject all cookies or expressly our cookies, that may suppose
          the unavailability to access to some of the services provided in this website and that the experience in it
          may be less satisfactory. </p>
      </Modal>
    </>
  );
};

export default Cookie;