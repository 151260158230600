import React from 'react';
import {ReactSVG} from 'react-svg'

const RoleItem = ({icon, title, text}) => {
  return (
    <div className='col-12 col-sm-6 col-md-4'>
      <div className='role_item'>
        <div className='role_item-icon'>
          <ReactSVG src={icon}/>
        </div>
        <div className='bold-20 mt-3 text-uppercase'>{title}</div>
        <div className='muted mt-2'>{text}</div>
      </div>
    </div>
  );
}

export default RoleItem;