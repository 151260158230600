import React from 'react';
import './plan.css';
import PlanItem from "./PlanItem";

const data = [
  {period: '1 month', price: '12.99', amount: '12.99', recommended: false, plan: 1},
  {period: '3 months', price: '9.99', amount: '29.97', recommended: false, plan: 3},
  {period: '12 months', price: '5.99', amount: '71.88', recommended: true, plan: 12},
]

const Plan = ({onClick}) => {
  return (
    <section className='plan' id='plan'>
      <div className="row">
        <h3 className='title'>Choose your Plan</h3>
        <div className="row g-3 g-lg-4">
          {data.map((item, i) => <PlanItem key={i} item={item} onClick={() => onClick(item.plan)} />)}
        </div>
      </div>
    </section>
  );
}

export default Plan;