import React, {useEffect, useState} from 'react';
import {Modal} from "react-responsive-modal";
import emailImg from '../assets/images/email.png';
import {type} from "@testing-library/user-event/dist/type";

const apiOptions = {
  method: 'POST', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json'
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer',
}

const EmailModal = ({visible, onClose, onOpenModalTerms}) => {
  const [email, setEmail] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [successSending, setSuccessSending] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!visible) {
      setEmail('');
      setAgreeTerms(false);
      setSuccessSending(false);
    }
  }, [visible]);

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      if (!email) return;
      localStorage.setItem('email', email);
      if (typeof visible === "number") {
        try {
          const response2 = await fetch(`https://api.futuris.school/subscription`, {...apiOptions, body:JSON.stringify({email, period: visible})});
          const data = await response2.json();
          const url = data.url;
          if (url) window.location.href = url;
        } catch (e) {
          setError(true);
        }
      } else {
        const response = await fetch(`https://api.futuris.school/externalApi/addOrder?email=${email}&api_key=qTmJXYYswsvs3Wn7vCCDfdsxLZ3jMIPvBHjUHL`, apiOptions);
        await response.json();
        setSuccessSending(true);
        setEmail('');
      }

    } catch (e) {

    }
  }
  return (
    <Modal open={visible} onClose={onClose} center showCloseIcon={false} animationDuration={0}
           styles={{modal: {minWidth: 500, width: 500}}}>
      <form onSubmit={handleSend}>

        {successSending
          ?
          <div className='text-center mb-3'>
            <h4 className='mb-3 bold'>Mail successfully sent</h4>
            <img src={emailImg} alt="email" style={{width: 100}}/>
          </div>
          :
          <>
            <h4 className='title-32'>Create account</h4>
            <p className='muted-14'>Please provide your email address to create an account and proceed to payment.</p>
            <hr/>

            <input type="email" value={email} onChange={(e) => {
              setError(false);
              setEmail(e.target.value)
            }} placeholder='E-mail'/>
            {error && <div className='text-danger mt-1'>Payment error</div>}
            <div className='d-flex mt-4'>
              <button type='submit' className={`btn btn-primary w-100 me-3`} disabled={!agreeTerms}>Create & Pay</button>
              <button type='button' className="btn btn-muted w-100 ms-3" onClick={onClose}>Close</button>
            </div>
            <div className="form-check mt-2">
              <input className="form-check-input" type="checkbox" checked={agreeTerms}
                     onChange={(e) => setAgreeTerms(e.target.checked)}/>
              <label className="form-check-label ms-2">
                I agree to the <a className='terms-link'
                                  href='https://docs.google.com/document/d/1M7_9ApwsL3Vm6e5jX_k__qShFe0zINMe9sFFm6lzhVs/edit?usp=sharing'
                                  target='_blank'>Terms of Service</a> and <a
                target='_blank'
                href='https://docs.google.com/document/d/1UhHdNYj5CVBF6WEHoosr-fsnyMG4-g4cnnzTHnolcw4/edit?usp=sharing'
                className='terms-link'>Privacy Policy</a>
              </label>
            </div>
          </>
        }
      </form>
    </Modal>
  );
};

export default EmailModal;