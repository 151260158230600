import React from 'react';

const TermsTeacher = () => {
  return (
    <>
      <h2 className='title-32'>Teacher Terms</h2>
      {/*<div className='bold'>These Teacher Terms were last updated June 6, 2022</div>*/}
      <div className='terms-content'>
        <p>When you sign up to become an Teacher on the Edcraze platform, you agree to abide by these Teacher Terms ("<b>Terms</b>"). These Terms cover details about the aspects of the Edcraze platform relevant to Teachers and are incorporated by reference into our Terms of Use, the general terms that govern your use of our Services. Any capitalized terms that aren't defined in these Terms are defined as specified in the Terms of Use.
        </p>
        <p>As a Teacher, you are contracting directly with Silda Group LTD, regardless of whether another Edcraze subsidiary facilitates payments to you.
        </p>
        <h3>1. Teacher Obligations</h3>
        <p>
          As a Teacher, you are responsible for all content that you post, including lectures, quizzes, coding exercises, practice tests, assignments, resources, answers, course landing page content, labs, assessments, and announcements ("<b>Submitted Content</b>").
        </p>
        <p>You represent and warrant that:
        </p>
        <ul>
          <li>you will provide and maintain accurate account information;
          </li>
          <li>you own or have the necessary licenses, rights, consents, permissions, and authority to authorize Edcraze to use your Submitted Content as specified in these Terms and the Terms of Use;
          </li>
          <li>your Submitted Content will not infringe or misappropriate any third party's intellectual property rights;
          </li>
          <li>you have the required qualifications, credentials, and expertise (including education, training, knowledge, and skill sets) to teach and offer the services that you offer through your Submitted Content and use of the Services; and
          </li>
          <li>you will ensure a quality of service that corresponds with the standards of your industry and instruction services in general.
          </li>
        </ul>
        <p>You warrant that you will not:</p>
        <ul>
          <li>post or provide any inappropriate, offensive, racist, hateful, sexist, pornographic, false, misleading, incorrect, infringing, defamatory or libelous content or information;
          </li>
          <li>post or transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, or any other form of solicitation (commercial or otherwise) through the Services or to any user;
          </li>
          <li>use the Services for business other than providing tutoring, teaching, and instructional services to students;
          </li>
          <li>engage in any activity that would require us to obtain licenses from or pay royalties to any third party, including the need to pay royalties for the public performance of a musical work or sound recording;
          </li>
          <li>frame or embed the Services (such as to embed a free version of a course) or otherwise circumvent the Services;
          </li>
          <li>impersonate another person or gain unauthorized access to another person's account;
          </li>
          <li>interfere with or otherwise prevent other Teachers from providing their services or content; or
          </li>
          <li>abuse Edcraze resources, including support services.
          </li>
        </ul>
        <h3>2. License to Edcraze</h3>
        <p>You grant Edcraze the rights detailed in the Terms of Use to offer, market, and otherwise exploit your Submitted Content. This includes the right to add captions or otherwise modify Submitted Content to ensure accessibility. You also authorize Edcraze to sublicense these rights to your Submitted Content to third parties, including to students directly and through third parties such as resellers, distributors, affiliate sites, deal sites, and paid advertising on third-party platforms.
        </p>
        <p>Unless otherwise agreed, you have the right to remove all or any portion of your Submitted Content from the Services at any time. Except as otherwise agreed, Edcraze's right to sublicense the rights in this section will terminate with respect to new users 60 days after the Submitted Content's removal. However, (1) rights given to students before the Submitted Content's removal will continue in accordance with the terms of those licenses (including any grants of lifetime access) and (2) Edcraze's right to use such Submitted Content for marketing purposes shall survive termination.
        </p>
        <p>We may record and use all or any part of your Submitted Content for quality control and for delivering, marketing, promoting, demonstrating, or operating the Services. You grant Edcraze permission to use your name, likeness, voice, and image in connection with offering, delivering, marketing, promoting, demonstrating, and selling the Services, your Submitted Content, or Edcraze's content, and you waive any rights of privacy, publicity, or other rights of a similar nature, to the extent permissible under applicable law.
        </p>
        <h3>3. Trust & Safety</h3>
        <h4>3.1 Trust & Safety Policies</h4>
        <p>You agree to abide by Edcraze's Trust & Safety policies, Restricted Topics policy, and other content quality standards or policies prescribed by Edcraze from time to time. You should check these policies periodically to ensure that you comply with any updates to them. You understand that your use of the Services is subject to Edcraze's approval, which we may grant or deny at our sole discretion.
        </p>
        <p>We reserve the right to remove content, suspend payouts, and/or ban Teachers for any reason at any time, without prior notice, including in cases where:
        </p>
        <ul>
          <li>an Teacher or content does not comply with our policies or legal terms (including the Terms of Use);
          </li>
          <li>content falls below our quality standards or has a negative impact on the student experience;
          </li>
          <li>an Teacher engages in behavior that might reflect unfavorably on Edcraze or bring Edcraze into public disrepute, contempt, scandal, or ridicule;
          </li>
          <li>an Teacher engages the services of a marketer or other business partner who violates Edcraze's policies;
          </li>
          <li>an Teacher uses the Services in a way that constitutes unfair competition, such as promotion of their off-site business in a way that violates Edcraze's policies; or
          </li>
          <li>as determined by Edcraze in its sole discretion.
          </li>
        </ul>
        <h4>3.2 Relationship to Other Users</h4>
        <p>Teachers don't have a direct contractual relationship with students, so the only information you'll receive about students is what is provided to you through the Services. You agree that you will not use the data you receive for any purpose other than providing your services to those students on the Edcraze platform, and that you won't solicit additional personal data or store students' personal data outside the Edcraze platform. You agree to indemnify Edcraze against any claims arising from your use of students' personal data.
        </p>
        <h4>3.3 Anti-Piracy Efforts</h4>
        <p>We partner with anti-piracy vendors to help protect your content from unauthorized use. To enable this protection, you hereby appoint Edcraze and our anti-piracy vendors as your agents for the purpose of enforcing copyrights for each of your content, through notice and takedown processes (under applicable copyright laws like the Digital Millennium Copyright Act) and for other efforts to enforce those rights. You grant Edcraze and our anti-piracy vendors primary authority to file notices on your behalf to enforce your copyright interests.
        </p>
        <h3>4. Pricing</h3>
        <h4>4.1 Price Setting</h4>
        <p>When creating Submitted Content available for purchase on Edcraze, you will be prompted to select a base price ("<b>Base Price</b>") for your Submitted Content from a list of available price tiers. Alternatively, you may choose to offer your Submitted Content for free. As a premium Teacher, you will also be given the opportunity to participate in certain promotional programs under the terms of our Promotions Policy ("<b>Promotional Programs</b>").
        </p>
        <p>If you do not opt to participate in any Promotional Programs, we will list your Submitted Content for the Base Price or the closest local or mobile app equivalent (as detailed below). If you opt to participate in a Promotional Program, we may set a different discounted price or list price for your Submitted Content as described in the Promotions Policy.
        </p>
        <p>When a student purchases using a foreign currency, we will convert the relevant Base Price or Promotional Program price into the student's applicable currency using a system-wide foreign currency conversion rate set by Edcraze.
        </p>
        <p>When a student purchases through a mobile application, the mobile platform provider's pricing matrix will control, and we will choose the price tier closest to the applicable Base Price or Promotional Program price.
        </p>
        <p>You give us permission to share your Submitted Content for free with our employees, with selected partners, and in cases where we need to restore access to accounts who have previously purchased your Submitted Content. You understand that you will not receive compensation in these cases.
        </p>
        <h4>4.2 Transaction Taxes</h4>
        <p>If a student purchases a product or service in a country that requires Edcraze to remit national, state, or local sales or use taxes, value added taxes (VAT), or other similar transaction taxes ("<b>Transaction Taxes</b>"), under applicable law, we will collect and remit those Transaction Taxes to the competent tax authorities for those sales. We may increase the sale price at our discretion where we determine that such taxes may be due. For purchases through mobile applications, applicable Transaction Taxes are collected by the mobile platform (such as Apple's App Store or Google Play).
        </p>
        <h4>4.3 Promotional Programs</h4>
        <p>Edcraze offers several optional marketing programs (Promotional Programs) in which you can choose to participate, as detailed in our Promotions Policy. These programs can help increase your revenue potential on Edcraze by finding the optimal price point for your Submitted Content and offering them through subscriptions collections.
        </p>
        <p>There is no up-front cost to participate in these programs, and you can modify your participation status at any time, though changes you make will not apply to currently active campaigns and certain programs may have additional requirements on termination.
        </p>
        <h3>5. Payments</h3>
        <h4>5.1 Revenue Share</h4>
        <p>When a student purchases your Submitted Content, we calculate the gross amount of the sale as the amount actually received by Edcraze from the student ("<b>Gross Amount</b>"). From this, we subtract any Transaction Taxes, any mobile platform fees applied to mobile provider checkout sales, a 3% service and processing fee for any non-mobile provider checkout sales, and any amounts paid to third parties in connection with the Promotional Programs to calculate the net amount of the sale ("Net Amount").
        </p>
        <p>If you have not opted into any of Edcraze's optional Promotional Programs, and except for sales through Teacher-generated coupon codes or course referral links as described below, your revenue share will be 37% of the Net Amount less any applicable deductions, such as student refunds. If we change this payment rate, we will provide you 30 days notice using prominent means, such as via email or by posting a notice through our Services.
        </p>
        <p>Edcraze makes all Teacher payments in U.S. dollars (USD) regardless of the currency with which the sale was made. Edcraze is not responsible for your foreign currency conversion fees, wiring fees, or any other processing fees that you may incur. Your revenue report will show the sales price (in local currency) and your converted revenue amount (in USD).
        </p>
        <h4>5.2 Receiving Payments</h4>
        <p>For us to pay you in a timely manner, you must own a Paypal account in good standing and must keep us informed of the correct email associated with your account. Depending on the applicable revenue share model, payment will be made within 45 days of the end of the month in which (a) we receive the fee for a course or (b) the relevant course consumption occurred.
        </p>
        <p>If we cannot settle funds into your payment account after the period of time set forth by your state, country, or other government authority in its unclaimed property laws, we may process the funds due to you in accordance with our legal obligations, including by submitting those funds to the appropriate government authority as required by law.
        </p>
        <h4>5.3 Refunds</h4>
        <p>You acknowledge and agree that students have the right to receive a refund, as detailed in the Terms of Use. Teachers will not receive any revenue from transactions for which a refund has been granted under the Terms of Use.
        </p>
        <p>If a student asks for a refund after we have paid the relevant Teacher payment, we reserve the right to either (1) deduct the amount of the refund from the next payment sent to the Teacher or (2) where no further payments are due to the Teacher or the payments are insufficient to cover the refunded amounts, require the Teacher to refund any amounts refunded to students for the Teacher's Submitted Content.
        </p>
        <h3>6. Trademarks</h3>
        <p>While you are a published Teacher and subject to the requirements below, you may use our trademarks where we authorize you to do so.
        </p>
        <p>You must:</p>
        <ul>
          <li>only use the images of our trademarks that we make available to you, as detailed in any guidelines we may publish;
          </li>
          <li>only use our trademarks in connection with the promotion and sale of your Submitted Content available on Edcraze or your participation on Edcraze; and
          </li>
          <li>immediately comply if we request that you discontinue use.
          </li>
        </ul>
        <p>You must not:
        </p>
        <ul>
          <li>use our trademarks in a misleading or disparaging way;
          </li>
          <li>use our trademarks in a way that implies that we endorse, sponsor, or approve of your Submitted Content or services; or
          </li>
          <li>use our trademarks in a way that violates applicable law or in connection with an obscene, indecent, or unlawful topic or material.
          </li>
        </ul>
        <h3>7. Miscellaneous Legal Terms</h3>
        <h4>7.1 Updating These Terms</h4>
        <p>From time to time, we may update these Terms to clarify our practices or to reflect new or different practices (such as when we add new features), and Edcraze reserves the right in its sole discretion to modify and/or make changes to these Terms at any time. If we make any material change, we will notify you using prominent means such as by email notice sent to the email address specified in your account or by posting a notice through our Services. Modifications will become effective on the day they are posted unless stated otherwise.
        </p>
        <p>Your continued use of our Services after changes become effective shall mean that you accept those changes. Any revised Terms shall supersede all previous Terms.
        </p>
        <h4>7.2 Translations</h4>
        <p>Any version of these Terms in a language other than English is provided for convenience and you understand and agree that the English language will control if there is any conflict.
        </p>
        <h4>7.3 Relationship Between Us</h4>
        <p>You and we agree that no joint venture, partnership, employment, contractor, or agency relationship exists between us.
        </p>
        <h4>7.4 Survival</h4>
        <p>The following sections shall survive the expiration or termination of these Terms: Sections 2 (License to Edcraze), 3.3 (Relationship to Other Users), 5.3 (Receiving Payments), 5.4 (Refunds), 7 (Miscellaneous Legal Terms).
        </p>
        <h3>8. How to Contact Us
        </h3>
        <p>The best way to get in touch with us is to contact our Support Team <a href="mailto:support@edcraze.com">support@edcraze.com</a>. We'd love to hear your questions, concerns, and feedback about our Services.
        </p>

      </div>
    </>
  );
}

export default TermsTeacher;
