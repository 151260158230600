import React from 'react';

const Policy = () => {
  return (
    <>
      <h2 className='title-32'>EDCRAZE PRIVACY NOTICE</h2>
      {/*<div className='bold'>Last Updated: June 14, 2022</div>*/}
      <div className='terms-content'>
        <p>
          Silda Group LTD (“Us”, “We”, or “Our”) understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website <a
          href="https://www.edcraze.com" target='_blank'>www.edcraze.com</a> (the “Site”) and uses services, features, content or applications offered by us (together with the Site, the “Services”) and we will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.
        </p>
        <p>
          Please read this Privacy Notice carefully and ensure that you understand it. If you have any questions, please contact us at: <a
          href="mailto:privacy@Edcraze.com">privacy@Edcraze.com</a>.
        </p>
        <h3>DEFINITIONS AND INTERPRETATION
        </h3>
      <p>In this Privacy Notice the following terms shall have the following meanings:
      </p>
        <ul>
          <li><b>Edcraze (Program/Service/Platform)</b> – a course platform for creating courses. The platform is the result of our intellectual activity, we have the exclusive rights for the Program.
          </li>
          <li><b>Account</b> - the Program's special section, allowing the User after its preliminary registration carried by us to receive information about content uploaded onto the Program, changing in its' status, and other information regarding the User’s activity;
          </li>
          <li><b>the User's Data</b> - any information, data, objects, materials which are possessed, created, uploaded, stored by the User in the Program during its usage;
          </li>
          <li><b>Territory</b> - the territory for which we are granted to you Service, namely: the territory of the Kingdom of Saudi Arabia, of the United Arab Emirates, of the People's Democratic Republic of Algeria, of the Hashemite Kingdom of Jordan, of the United Mexican States.
          </li>
          <li><b>Data Protection Legislation</b> - means any national implementing laws, regulations, and secondary legislation (as amended from time to time), depending on Territory and User (For example the Saudi Arabia’s Personal Data Protection Law (PDPL), the law № 18-07 of the People's Democratic Republic of Algeria, the Federal Decree Law of the United Arab Emirates No. 45 of 2021 on the Protection of Personal Data (“DPL”), Data Protection Legislation of the United Mexican States).
          </li>
        </ul>
        <h3>INFORMATION ABOUT US
        </h3>
        The Site and Platform are owned and operated by Silda Group LTD. <br/>
        Address: 85 Great Portland Street, First Floor, London, United Kingdom, W1W 7LT <br/>
        e-mail: <a href="mailto:privacy@Edcraze.com">privacy@Edcraze.com</a>
        <h3>WHAT DOES THIS PRIVACY NOTICE COVER?
        </h3>
        <p>This Privacy Notice applies only to your use of Site and Platform. The Site and Platform may contain links to other websites (Third Party Services). Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them. We cannot take responsibility for the content or privacy policies of those Third Party Services.
        </p>
        <p>This Privacy Notice is intended to inform you about the information and data gathered by us, how we store your information, information we collect and to the degree your information may be used.
        </p>
        <p>We may update this Privacy Notice from time to time.  If you are a registered Edcraze user, we will attempt to inform you of any material changes by email. Otherwise, please check back frequently on the Edcraze website (<a
          href="https://www.edcraze.com" target='_blank'>www.edcraze.com</a>) for the latest and most updated Privacy Notice.
        </p>
        <p>We provide you with the opportunity to review, remove and modify any personal information that you provided previously.  Please contact us directly by email to make changes to your personal information. You may also update your personal information by logging into your Edcraze account and updating your details.
        </p>
        <h3>WHAT IS PERSONAL DATA?
        </h3>
        <p>Personal data is any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier.
        </p>
        <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.
        </p>
        <h3>WHAT DATA DO WE COLLECT?
        </h3>
        <p>The information we gather enables us to personalize, improve, and continue to operate the Services. In connection with certain aspects of the Services, we may request, collect, and/or display some of your Personal Information. We collect the following types of information from our users.
        </p>
        <ul>
          <li>Account Information: <br/> When you create an Account, you will provide information that could be Personal Information, such as your username, password, and email address. You acknowledge that this information may be personal to you, and by creating an Account on the Services and providing Personal Information to us, you allow others, including us, to identify you and therefore may not be anonymous. We may use your contact information to send you information about our Services, but only rarely when we feel such information is important. You may unsubscribe from these messages through the messages themselves, regardless, we reserve the right to contact you when we believe it is necessary, such as for account recovery purposes.
          </li>
          <li>Cookies: <br/>We do not use cookies. We do not control when or how third parties place cookies on your computer. For example, third party websites to which a link points may set cookies on your computer.
          </li>
        </ul>
        <h3>HOW ARE WE RECEIVED THE DATA?
        </h3>
        <p>Under the Data Protection Legislation, we must always have a lawful basis for using personal data. This may be because the data is necessary for our performance of a contract with you, because you have consented to our use of your personal data, or because it is in our legitimate business interests to use it.
        </p>
        <p>We receive information about you from various sources, including: (i) if you register for the Site and the Services, through your user account on the Services (your “Account”); and (ii) your use of the Services generally. When you use the Services, you are consenting to the collection, transfer, manipulation, storage, disclosure, and other uses of your information as described in this Privacy Notice.
        </p>
        <h3>HOW, AND WITH WHOM IS MY INFORMATION SHARED?
        </h3>
        <p>The Services are designed to help you share information with others. As a result, some of the information generated through the Services is shared publicly or with third parties.
        </p>
        <ul>
          <li><b>Public Information about Your Activity on the Services:</b><br/> Some of your activity on and through the Services is public by default. This may include, but is not limited to, content you have posted publicly on the Site or otherwise through the Services. Registered users may have some of this information associated with their Accounts. Please also remember that if you choose to provide Personal Information using certain public features of the Services, then that information is governed by the privacy settings of those particular features and may be publicly available. Individuals reading such information may use or disclose it to other individuals or entities without our control and without your knowledge, and search engines may index that information. We, therefore, urge you to think carefully about including any specific information you may deem private in content that you create or information that you submit through the Services.
          </li>
          <li><b>Information You Elect to Share:
          </b><br/>You may access other Third Party Services through the Services, for example by clicking on links to those Third Party Services from within the Site. We are not responsible for the privacy policies and/or practices of these Third-Party Services, and you are responsible for reading and understanding those Third Party Services’ privacy policies. This Privacy Notice only governs information collected on the Services.
          </li>
          <li><b>Aggregate Information:
          </b><br/>We share Aggregate Information with our partners, service providers, and other persons with whom we conduct business. We share this type of statistical data so that our partners can understand how and how often people use our Services and their services or websites, which facilitates improving both their services and how our Services interface with them. In addition, these third parties may share with us non-private, aggregated, or otherwise non Personal Information about you that they have independently developed or acquired.
          </li>
          <li><b>Email Communications with Us:
          </b><br/>As part of the Services, you may occasionally receive email and other communications from us, such as communications relating to your Account. Communications relating to your Account will only be sent for purposes important to the Services, such as password recovery.
          </li>
          <li><b>User Profile Information:
          </b><br/>User profile information including your username and other information you enter may be displayed to other users to facilitate user interaction within the Services. We will not directly reveal user email addresses to other users.
          </li>
          <li><b>Information Shared with Our Agents:
          </b><br/>We employ and contract with people and other entities that perform certain tasks on our behalf and who are under our control (our “Agents”). We may need to share Personal Information with our Agents in order to provide products or services to you. Unless we tell you differently, our Agents do not have any right to use Personal Information or other information we share with them beyond what is necessary to assist us. You hereby consent to our sharing of Personal Information with our Agents.
          </li>
          <li><b>Information Disclosed for Our Protection and the Protection of Others:
          </b><br/>We also reserve the right to access, read, preserve, and disclose any information as it reasonably believes is necessary to (i) satisfy any applicable law, regulation, legal process, or governmental request, (ii) enforce this Privacy Notice and our Terms of Service, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect our rights, property or safety, our users and the public. This includes exchanging information with other companies and organizations for fraud protection and spam/malware prevention.
          </li>
          <li><b>Information We Share With Your Consent:
          </b><br/>Except as set forth above, you will be notified when your Personal Information may be shared with third parties and will be able to prevent the sharing of this information.
          </li>
        </ul>
        <h3>HOW LOND WILL YOU KEEP MY PERSONAL DATA?
        </h3>
        <p>We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Your personal data will therefore be kept for the following periods (or, where there is no fixed period, the following factors will be used to determine how long it is kept):
        </p>
        <ul>
          <li>we will keep your data for maximum period of two years following your initial communication or signup/personal data submission, or
          </li>
          <li>for as long as you keep an account with Platform, or
          </li>
          <li>other period established by law.
          </li>
        </ul>
        <h3>IS INFORMATION ABOUT ME SECURE?
        </h3>
        <p>Your Account information will be protected by a password for your privacy and security. You need to prevent unauthorized access to your Account and Personal Information by selecting and protecting your password appropriately and limiting access to your computer and browser by signing off after you have finished accessing your Account.
        </p>
        <p>We seek to protect Account information to ensure that it is kept private; however, we cannot guarantee the security of any Account information. Unauthorized entry or use, hardware or software failure, and other factors may compromise the security of user information at any time.</p>
      <p>We employ and protect all data with SSL encryption and other security measures to ensure that your data is protected and safe. Please be advised that while we take extra measures to protect your data and the integrity of your information, we cannot guarantee that our security measures will prevent unauthorized access from occurring. Please take the proper steps to maintain the security of your account information. We highly recommend that you set a strong password for your registered account with Edcraze to ensure others from easily guessing your password.
      </p>
      <p>Passwords are encrypted before being written to the database. This means that there are never plaintext passwords stored in the database. Passwords cannot be retrieved, only reset, to protect privacy at the highest level.
      </p>
        <p>Our database has several layers of encryption security. Complex logic has been developed and deployed to detect malicious activity with swift banning implementation to prevent any hacking attempts. Beyond this, we do not disclose our private security measures.
        </p>
        <h3>WHAT INFORMATION OF MINE CAN I ACCESS?
        </h3>
        <p>If you are a registered user, you can access information associated with your Account by logging into the Services. If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”.
        </p>
        <p>All subject access requests should be made in writing and sent to the email or postal addresses shown in the section “INFORMATION ABOUT US”.
        </p>
        <p>There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.
        </p>
        <p>We will respond to your subject access request within 21 days and, in any case, not more than one month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.
        </p>
        <h3>HOW CAN I DELETE MY ACCOUNT?
        </h3>
        <p>Should you ever decide to delete your account, you may do so by visiting your account settings page or by emailing <a
          href="mailto:support@edcraze.com">support@edcraze.com</a>.
        </p>
        <p>If you terminate your Account, any association between your Account and information we store will no longer be accessible through your Account. However, given the nature of sharing on the Services, any public activity on your Account prior to deletion will remain stored on our servers and will remain accessible to the public.
        </p>
        <h3>WHAT CHOICES DO I HAVE REGARDING MY INFORMATION?
        </h3>
        <p>You can always opt not to disclose certain information to us, even though it may be needed to take advantage of some of our features.
        </p>
        <p>If you wish to have your account completely deleted please note that we will need to verify that you have the authority to delete the Account, and activity generated prior to deletion will remain stored by us and may be publicly accessible. Contact us at <a
          href="mailto:support@edcraze.com">support@edcraze.com</a> if you’d like your account and data permanently deleted.
        </p>
        <h3>WHAT ARE MY RIGHTS?
        </h3>
        <p>If you are a resident of the Kingdom of Saudi Arabia or of the People's Democratic Republic of Algeria, you have certain data protection rights. In certain circumstances, you have the following data protection rights:
        </p>
        <ul>
          <li>The right to access and right to obtain: (i) confirmation of whether his or her personal data is processed or not, the purposes of the processing, the categories of data to which it relates and the recipients; (ii) communication, in an intelligible form, of his or her data which is the subject of processing, as well as any available information on the origin of the data.
          </li>
          <li>The right of rectification and to obtain: (i) updating, rectification, erasure or blocking of personal data whose processing does not comply with this law, in particular because of the incomplete or inaccurate nature of such data or whose processing is prohibited by the law; (ii) notification to third parties to whom the personal data has been communicated of any updating, rectification, erasure or blocking of personal data carried out in accordance with point above.
          </li>
          <li>The objection right, for legitimate reasons, to the processing of his personal data.
          </li>
        </ul>
        <p>If you are a resident of the United Arab Emirates, you have certain data protection rights. In certain circumstances, you have the following data protection rights:
        </p>
        <ul>
          <li>The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.
          </li>
          <li>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.
          </li>
          <li>The right to object. You have the right to object to our processing of your Personal Data.
          </li>
          <li>The right of restriction. You have the right to request that we restrict the processing of your personal information.
          </li>
          <li>The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.
          </li>
          <li>The right to withdraw consent. You also have the right to withdraw your consent at any time where we relied on your consent to process your personal information.
          </li>
        </ul>
        <p>If you are a resident of the United Mexican States, you have certain data protection rights. In certain circumstances, you have the following data protection rights:
        </p>
        <ul>
          <li>The right of access. You are entitled to access your personal data held by us, as well as information regarding the conditions and generalities of the processing.
          </li>
          <li>The right of rectification. You may request, at any time, that us rectify personal data if it is inaccurate or incomplete.
          </li>
          <li>The right of cancellation. You have the right to cancel (i.e. seek erasure of) its personal data. There are certain situations where we have the right to object to such erasure (e.g. if required by applicable law or public interest).
          </li>
          <li>The right of objection. You may, at any time, oppose the processing of their personal data for legitimate purposes.
          </li>
        </ul>
        <p>We aim to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data. If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
        </p>
        <p>Please note that we may ask you to verify your identity before responding to such requests.
        </p>
        <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in your country.
        </p>
        <h3>WHAT IF I HAVE QUESTIONS OR CONCERNS?
        </h3>
        <p>If you have any questions or concerns regarding privacy using the Services, please send us a detailed message to <a
          href="mailto:privacy@edcraze.com">privacy@edcraze.com</a>. We will make every effort to resolve your concerns.
        </p>
      </div>
    </>
  );
}

export default Policy;