import React from 'react';
import './creators.css';
import comp from '../../assets/images/icons/comp.svg';
import chandelier from '../../assets/images/icons/chandelier.svg';
import yoga from '../../assets/images/icons/yoga.svg';
import hairbrush from '../../assets/images/icons/hairbrush.svg';
import tassel from '../../assets/images/icons/tassel.svg';
import apple from '../../assets/images/icons/apple.svg';
import CreatorsItem from "./CreatorsItem";

const data = [
  {icon: comp, title: 'IT specialists',},
  {icon: chandelier, title: 'Psychologists',},
  {icon: yoga, title: 'Yoga instructors'},
  {icon: hairbrush, title: 'Stylists'},
  {icon: tassel, title: 'Makeup artists'},
  {icon: apple, title: 'Nutrition specialists'},
]

const Creators = () => {
  return (
    <section className='creators'>
      <div className="row">
        <div className="col-12 col-md-8 pe-0"><h3 className='title'>These creators have already chosen us</h3></div>
      </div>
      <div className="row creators_list">
        <div className='col-12 col-md-8 offset-md-4'>
          {data.map((item, i) => <CreatorsItem key={i} {...item} />)}
        </div>
      </div>
    </section>
  );
}

export default Creators;