import React from 'react';

const MadeSimpleItem = ({icon, idx, title, text}) => {
  return (
    <div className='col-12 col-sm-4'>
      <div className=' made-simple_item'>
      <img src={icon} alt="img" className='made-simple_item-img'/>
      <div className='bold-20 mt-4 mt-sm-3'>0{idx + 1}</div>
      <div className='bold-20 mt-2 text-uppercase'>{title}</div>
      <div className='muted mt-2'>{text}</div>
      </div>
    </div>
  );
}

export default MadeSimpleItem;