import React from 'react';

const Terms = () => {
  return (
    <>
      <h2 className='title-32'>EDCRAZE TERMS OF SERVICE</h2>
      {/*<div className='bold'>Last Updated: June 14, 2022</div>*/}
      <div className='terms-content'>
        <p>Please read these Terms of Service fully and carefully before using www.edcraze.com (the “Site”) and the
          services, features, content or applications offered by Silda Group LTD (“we”, “us” or “our”), (together with the
          Site, the “Services”). These Terms of Service set forth the legally binding terms and conditions for your use
          of the Site and the Services. Acceptance of Terms of Service
        </p>
        <p>(А). By registering for and/or using the Services in any manner, including but not limited to visiting or
          browsing the Site, you agree to these Terms of Service and all other operating rules, policies and procedures
          that may be published from time to time on the Site by us, each of which is incorporated by reference and each
          of which may be updated from time to time without notice to you. Your continued use of the Services following
          any such update will be taken as confirmation of your acceptance of such updates.
        </p>
        <p>(B). Certain of the Services may be subject to additional terms and conditions specified by us from time to
          time; your use of such Services is subject to those additional terms and conditions, which are incorporated
          into these Terms of Service by this reference.
        </p>
        <p>(C). These Terms of Service apply to all users of the Services, including, without limitation, users who are
          contributors of content, information, and other materials or services, registered or otherwise.
        </p>
        <p>You may use the Services only in accordance with these Terms. The Services will continue to evolve as we
          refine features and functionality. We may terminate, suspend, or modify the Services, in general or with
          respect to you, from time to time without cause or prior notice. We may also delete any content or data from
          the Services at our discretion.
        </p>
        <h3>1. TERMS AND DEFINITIONS</h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <ol>
            <li><b>Edcraze (Program/Service/Platform)</b> – a course platform for creating courses. The platform is the
              result of our intellectual activity, we have the exclusive rights for the Program.
            </li>
            <li><b>Account</b> - the Program's special section, allowing the User after its preliminary registration
              carried by us to receive information about content uploaded onto the Program, changing in its' status, and
              other information regarding the User’s activity;
            </li>
            <li><b>the User's Data</b> - any information, data, objects, materials which are possessed, created,
              uploaded, stored by the User in the Program during its usage;
            </li>
            <li><b>Non-exclusive License</b> - a license under which the Software can be used by us itself, by the User
              and by the third parties on the Territory;
            </li>
            <li><b>Intellectual Property</b> - copyrights, trademarks, patent rights and other intellectual property
              rights which can exist all over the world whether they are registered or not;
            </li>
            <li><b>Territory</b> - the territory for which we are granted to you Service, namely: the territory of the
              Kingdom of Saudi Arabia, of the United Arab Emirates, of the People's Democratic Republic of Algeria, of
              the Hashemite Kingdom of Jordan, of the United Mexican States.
            </li>
            <li><b>Confidential Information</b> - (a) any information disclosed to one Party by other Party within the
              Period of Validity of these Terms (being disclosed in written, in oral or in other form) which at the
              moment of its disclosure:<br/>
              (i) was marked or described as "confidential"; or <br/>
              (ii) shall be reasonably interpreted as confidential by the Parties; and<br/>
              (b) all data of technical, manufacture and commercial nature that was presented by us or which the User
              became aware of by other means in connection with the execution of these Terms.
            </li>
            <li><b>Law</b> - the law of any of the countries specified in clause 1.6. (Depending on territory).
            </li>
            <li><b>Content</b> - includes, without limitation, information, data, text, photographs, videos, audio
              clips, written posts and comments, software, scripts, graphics, and interactive features generated,
              provided, or otherwise made accessible on or through the Services.
            </li>
          </ol>
        </ol>
        <h3>2. ELIGIBILITY</h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>You may use the Services only if you have the legal power and capacity to form a contract with us. You
              represent and warrant that you are of the age of majority under Law. If you have not yet reached the age
              of majority, you may not, under any circumstances or for any reason, use the Services. We may, in our sole
              discretion, refuse to offer the Services to any person and change its eligibility criteria at any time.
              You are solely responsible for ensuring that these Terms of Service are in compliance with all laws,
              rules, and regulations applicable to you and the right to access the Services is revoked where these Terms
              of Service or use of the Services is prohibited or to the extent offering, sale or provision of the
              Services conflicts with any applicable law, rule or regulation. Further, the Services are offered only for
              your use, and not for the use or benefit of any third party.
            </li>
          </ol>
        </ol>
        <h3>3. REGISTRATION</h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>

          <ol>
            <li>To sign up for the Services, you must register for an account on the Services (an “Account”). You must
              provide accurate and complete information and keep your Account information updated. You are solely
              responsible for the activity that occurs on your Account, and for keeping your Account password secure.
              You may never use another person’s user account or registration information for the Services without
              permission. You must notify us immediately of any change in your eligibility to use the Services
              (including any changes to or revocation of any licenses from state authorities), breach of security or
              unauthorized use of your Account. You should never publish, distribute or post login information for your
              Account. You shall have the ability to delete your Account, either directly or through a request made to
              one of our employees or affiliates.
            </li>
          </ol>
        </ol>
        <h3>4. CONTENT</h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>The Services may contain Content specifically provided by us, our partners, or our users and such
              Content is protected by copyrights, trademarks, service marks, patents, trade secrets, or other
              proprietary rights and laws. You shall abide by and maintain all copyright notices, information, and
              restrictions contained in any Content accessed through the Services.
            </li>
            <li>We do not guarantee that any Content will be made available on the Site or through the Services. We
              reserve the right to, but do not have any obligation to, (i) remove, edit or modify any Content in our
              sole discretion, at any time, without notice to you and for any reason (including, but not limited to,
              upon receipt of claims or allegations from third parties or authorities relating to such Content or if we
              are concerned that you may have violated these Terms of Service), or for no reason at all and (ii) to
              remove or block any Content from the Services.
            </li>
          </ol>
        </ol>
        <h3>5. USE LICENSE
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>Subject to these Terms of Service, we grant each user of the Services the limited, non-exclusive,
              non-transferable, revocable license to use (i.e., to download and display locally) Content solely for
              purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content
              for other than purposes of using the Services is expressly prohibited without prior written permission
              from us. You shall not sell, license, rent, or otherwise use or exploit any Content for commercial use or
              in any way that violates any third party right.
            </li>
          </ol>
        </ol>
        <h3>6. RULES OF CONDUCT
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>As a condition of use, you promise not to use the Services for any purpose that is prohibited by these
              Terms of Service. You are responsible for all of your activity in connection with the Services.
            </li>
            <li>You have agreed, that the Program is presented by us on an “as is” basis, regarding it is impossible to
              develop a software containing no errors and faults. Additionally, we shall take reasonable measures from
              the commercial perspective to maintain the Program operation and to eliminate all possible errors and
              faults of the Program in order to ensure the possibility of using the Program in compliance with these
              Terms of Service.
            </li>
            <li>You accepts the presence of risks connected with granting the access to the Program or with the
              connection and work with the Third-Party Services via the Program, or in connection with them; also
              confirms, that we cannot guarantee (and do not guarantee) the actual results of such use or interactions,
              and that you takes all of the abovementioned risks, obligations and responsibility for any harm caused in
              connection with or out of the specified interaction at his account. The abovementioned risks may include,
              among others, miscommunication of the Third-Party Services information from the third parties, violation
              of contract or guarantee obligations by the third parties, violation of rights and the followed-up claims.
            </li>
            <li>We do not recommend to use the Program for hosting personal User’s Data or other confidential
              information, and does not bear any responsibility for ensuring of this information security or integrity,
              as well as for the risks connected with the violation of rights in respect of specified information by the
              third parties, or with the damage caused to you by any of the third parties.
            </li>
            <li>You are notified or will be notified in the future that some of the Program functions may undergo
              BETA-testing. You accept and agree with the fact, that such functions of the Program may contain software
              errors, operate incorrectly and deviate from the work plan. Acceptance of the BETA-version of the Program
              by you means you agrees for participation in BETA-testing of relevant functions.
            </li>
            <li> You shall not (and shall not permit any third party to) either (a) take any action or (b) upload,
              download, post, submit or otherwise distribute or facilitate distribution of any Content on or through the
              Service that:
              <ul>
                <li>infringes any patent, trademark, trade secret, copyright, right of publicity or other right of any
                  other person or entity or violates any law or contractual duty;
                </li>
                <li>you know is false, misleading, untruthful or inaccurate;
                </li>
                <li>is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive
                  of another's privacy, tortious, obscene, vulgar, pornographic, offensive, profane, contains or depicts
                  nudity, contains or depicts sexual activity, or is otherwise inappropriate as determined by us in our
                  sole discretion;
                </li>
                <li>constitutes unauthorized or unsolicited advertising, junk or bulk e-mail (“spamming”);
                </li>
                <li>contains software viruses or any other computer codes, files, or programs that are designed or
                  intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or
                  telecommunications equipment or to damage or obtain unauthorized access to any system, data, password
                  or other information of ours or of any third party;
                </li>
                <li>impersonates any person or entity, including any of our employees or representatives; or
                </li>
                <li>includes anyone’s identification documents or sensitive financial information.
                </li>
              </ul>
            </li>
            <li> You shall not: (i) take any action that imposes or may impose (as determined by us in our sole
              discretion) an unreasonable or disproportionately large load on our (or our third-party providers’)
              infrastructure; (ii) interfere or attempt to interfere with the proper working of the Services or any
              activities conducted on the Services; (iii) bypass, circumvent or attempt to bypass or circumvent any
              measures we may use to prevent or restrict access to the Services (or other accounts, computer systems or
              networks connected to the Services); (iv) run any form of auto-responder or “spam” on the Services; (v)
              use manual or automated software, devices, or other processes to “crawl” or “spider” any page of the Site;
              (vi) harvest or scrape any Content from the Services; (vii) otherwise take any action in violation of our
              guidelines and policies; or (viii) make return requests, track or attempt to track other users, or
              otherwise interfere with other users' rights to privacy and other rights, or collect personally
              identifiable information of users of the Program without their express informed consent.
            </li>
            <li>You shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or
              otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Services
              (including without limitation any application), except to the limited extent applicable laws specifically
              prohibit such restriction, (ii) modify, translate, or otherwise create derivative works of any part of the
              Services, or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive
              hereunder. You shall abide by all applicable local, state, national and international laws and
              regulations.
            </li>
            <li>We are not responsible for the quality of services (in particular, but not limited to, data transfer
              services) required to work with the Program if they are organized by third parties not involved by us.
            </li>
            <li>You agree that in order to work with the Program, he needs to use software (web browsers, operating
              systems, etc.) and equipment (personal computers, network equipment, etc.) produced and provided by third
              parties, and we cannot be held responsible for the quality of their work.
            </li>
            <li>In case of loss of the User's data caused by the actions of the User, data recovery is carried out at
              the written request of the User. Data recovery is carried out only if it is technically possible.
            </li>
            <li>You are solely responsible for the safety and confidentiality of the registration data (Account):
              login(s) and password(s). All actions performed using the login(s) and password(s) of you are considered
              to be performed by you.
            </li>
            <li>We also reserve the right to access, read, preserve, and disclose any information as we reasonably
              believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request,
              (ii) enforce these Terms of Service, including investigation of potential violations hereof, (iii) detect,
              prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests,
              or (v) protect the rights, property or safety of us, our users and the public.
            </li>
          </ol>
        </ol>
        <h3>7. THIRD-PARTY SERVICES
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>Services may permit you to link to other websites, services or resources on the Internet, and other
              websites, services or resources may contain links to the Services. When you access third party resources
              on the Internet, you do so at your own risk. These other resources are not under our control, and you
              acknowledge that we are not responsible or liable for the content, functions, accuracy, legality,
              appropriateness or any other aspect of such websites or resources. The inclusion of any such link does not
              imply our endorsement or any association between us and their operators. You further acknowledge and agree
              that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by or in connection with the use of or reliance on any such content, goods or
              services available on or through any such website or resource.
            </li>
          </ol>
        </ol>
        <h3>8. FEES FOR USE OF SERVICE
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>The Service may be provided to You without charge up with certain limits. But at any time, we may impose
              fees for the use of our services and will notify you on our website or in these Terms of Service.
            </li>
          </ol>
        </ol>
        <h3>9. TERMINATION</h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>We may terminate your access to all or any part of the Services at any time, with or without cause, with
              or without notice, effective immediately, which may result in the forfeiture and destruction of all
              information associated with your membership. If you wish to terminate your Account, you may do so by
              following the instructions on the Site or through the Services or contact us via email at <a href="mailto:support@edcraze.com">support@edcraze.com</a>. All provisions of these Terms of Service which by their nature should survive
              termination shall survive termination, including, without limitation, ownership provisions, warranty
              disclaimers, indemnity and limitations of liability.
            </li>
          </ol>
        </ol>
        <h3>10. WARRANTY DISCLAIMER
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>We have no special relationship with or fiduciary duty to you. You acknowledge that we have no duty to take any action regarding:
              <ul>
                <li>which users gain access to the Services;
                </li>
                <li>what Content you access via the Services; or
                </li>
                <li>how you may interpret or use the Content.
                </li>
              </ul>
            </li>
            <li>You release us from all liability for you having acquired or not acquired Content through the Services. We make no representations concerning any Content contained in or accessed through the Services, and we will not be responsible or liable for the accuracy, copyright compliance, or legality of material or Content contained in or accessed through the Services.
            </li>
          </ol>
        </ol>
        <h3>11. RESPONSIBILITY
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>In all cases stipulated by the applicable law, we do not bear any responsibility before the User for any direct, indirect, occasional, penal or similar losses borne due to: <br/>
              (a) the User’s Data faults or errors;<br/>
              (b) the harm caused to the health or property connected with the use of the Program;<br/>
              (c) unauthorized access to the Program, Account or personal, confidential information or other information stored, or out of its use;<br/>
              (d) improper operation of the Program functionality by the User;<br/>
              (e) breaks in data exchange with the Program or canceling of such exchange;<br/>
              (f) impossibility to use, data and profit loss, stigmatization or other immaterial losses which arose out of usage or its impossibility of any or all functions of the Program;<br/>
              (g) events beyond we will, including Internet or equipment failures, power outages, strikes, civil unrest, fires, accidents, explosions, terrorist attacks, military operations, natural disasters, regulations, acts or omissions of government agencies, and other force majeure events.
            </li>
            <li>We shall not be liable and shall not reimburse the User's losses caused by violations and/or errors in the operation of the Program resulting from illegal actions of the Users or third parties, as well as malfunctions of technical means and failures of electrical equipment.
            </li>
            <li>We shall not be liable for any failure to perform our obligations hereunder where such failure results from force majeure circumstances that directly or indirectly prevent the execution of our obligations, that is, such circumstances that are independent of the will of us, could not be foreseen by us at the time of conclusion of the Terms and prevented by reasonable means upon their occurrence (force majeure) including, without limitation, mechanical, electronic or communications failure, war and hostilities, uprisings, epidemics, earthquakes, floods, acts of authorities directly affecting the subject of these Terms.
            </li>
          </ol>
        </ol>
        <h3>12. GOVERNING LAW AND JURISDICTION
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>These Terms of Service shall be governed by and construed in accordance with the laws of England and Wales regardless of conflict of laws rules that may require the application of the laws of another jurisdiction. You agree that any dispute arising from or relating to the subject matter of these Terms of Service shall be governed by the exclusive jurisdiction High Court of England and Wales.
            </li>
          </ol>
        </ol>
        <h3>13. MODIFICATION
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>We reserve the right, in our sole discretion, to modify or replace any of these Terms of Service, or change, suspend, or discontinue the Services (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the Site or by sending you notice through the Services, via e-mail or by another appropriate means of electronic communication. We may also impose limits on certain features and services or restrict your access to parts or all of the Services without notice or liability. While we will timely provide notice of modifications, it is also your responsibility to check these Terms of Service periodically for changes. Your continued use of the Services following notification of any changes to these Terms of Service constitutes acceptance of those changes, which will apply to your continued use of the Services going forward. Your use of the Services is subject to the Terms of Service in effect at the time of such use.
            </li>
          </ol>
        </ol>
        <h3>14. MISCELLANEOUS
        </h3>
        <ol>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <li className='invisible position-absolute'></li>
          <ol>
            <li>These Terms of Service are the entire agreement between you and us with respect to the Services, including use of the Site, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us with respect to the Services. If any provision of these Terms of Service is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms of Service will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.
            </li>
            <li>These Terms of Service are personal to you, and are not assignable, transferable or sublicensable by you except with our prior written consent. We may assign, transfer or delegate any of our rights and obligations hereunder without consent.
            </li>
            <li>Unless otherwise specified in these Term of Service, all notices under these Terms of Service will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after it is sent, if sent for next day delivery by recognized overnight delivery service. Electronic notices should be sent to <a
                href="mailto:support@edcraze.com">support@edcraze.com</a>.
            </li>
          </ol>
        </ol>
        <h3>15. Contact:
        </h3>
        <div className='bold'>
          Silda Group LTD <br/>
          85 Great Portland Street, First Floor, London, United Kingdom, W1W 7LT <br/>
          <a href="mailto:support@edcraze.com">support@edcraze.com</a>
        </div>
      </div>
    </>
  );
}

export default Terms;
