import React, {useState} from 'react';
import './slider.css';
import SlickSlider from 'react-slick';
import screenshot1 from '../../assets/images/slider/screenshot1.jpg';
import screenshot2 from '../../assets/images/slider/screenshot2.jpg';
import screenshot3 from '../../assets/images/slider/screenshot3.jpg';
import screenshot4 from '../../assets/images/slider/screenshot4.jpg';
import screenshot5 from '../../assets/images/slider/screenshot5.jpg';
import screenshot6 from '../../assets/images/slider/screenshot6.jpg';
import CarouselArrowBtn from "./CarouselArrowBtn";

const images = [screenshot1, screenshot2, screenshot3, screenshot4, screenshot5, screenshot6];
const titles = [
  'Designing any type of course',
  'Dividing student groups for separate classes and adding mentors to each group',
  'Creating  webinars, videos, online lessons and any materials for your students',
  'Checking hometasks and discussing mistakes and testing progress',
  'Generating leads for your courses and increasing profit',
  'Analyzing your strengths and weaknesses with in-built  CRM',
]

const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CarouselArrowBtn type="next"/>,
    prevArrow: <CarouselArrowBtn type="prev"/>,
    afterChange: setActiveSlide,
    beforeChange: setActiveSlide,
  };
  return (
    <section className='slider'>
      <h3 className='title'>One platform for</h3>
      <div className="row slider-wrap">
        <div className="col-12 col-md-4">
          <div className='bold-20'>{activeSlide + 1} / {images.length}</div>
          {}
          <div className='bold-20 slide-titles'>
            {titles.map((title, i) => (
              <div className={`slide-title${activeSlide === i ? ' animate__fadeInUp active' : ''} animate__animated`}
                   key={i}>{title}</div>
            ))}
          </div>
        </div>
        <div className="col-12 col-md-8">
          <SlickSlider {...settings} >
            {images.map(((item, i) => (
              <img src={item} alt="slide" className='slider-item_img' key={i}/>
            )))}
          </SlickSlider>
        </div>
      </div>
    </section>
  );
}

export default Slider;