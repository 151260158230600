import React, {useEffect, useRef} from 'react';
import './header.css';
import logo from '../../assets/images/logo.png';
import {Link} from "react-router-dom";
import {scrollToPlan} from "../../modules/utils";

const Header = ({onFreeClick}) => {



  return (
    <header>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <Link to='/'>
            <img src={logo} alt="logo" className='logo'/>
          </Link>
          {onFreeClick
            ?
            <div className='btn btn-primary' onClick={scrollToPlan}>Choose Plan</div>
            :
            null
          }

        </div>
      </div>
    </header>
  );
}

export default Header;

