import React from 'react';
import './made_simple.css';
import usability from '../../assets/images/applications/usability.png';
import face from '../../assets/images/applications/face.png';
import wallet from '../../assets/images/applications/wallet.png';
import MadeSimpleItem from "./MadeSimpleItem";

const data = [
  {
    icon: usability,
    title: 'Usability',
    text: 'Made for teachers by teachers. Only easy-to-apply tools for designing courses. Sign up and create courses just a few minutes.'
  },
  {
    icon: face,
    title: 'Freedom of choice',
    text: 'You can create an unlimited number of courses, videos, hometasks etc. Integrated tools to suit your unique teaching method. Whoever your target audience is, you’ll find all the features you need in 1 place'
  },
  {
    icon: wallet,
    title: 'Easy profit',
    text: 'Monetize your expertise with NO additional charges. Happy customers will always subscribe to your new courses. Satisfaction boost from learning is the main key to your income'
  },
]

const MadeSimple = () => {
  return (
    <section className='made-simple'>
      <div className="row">
        <div className="col-12 col-md-8 pe-0"><h3 className='title'>We made it simple</h3></div>
        <div className="col-12 col-md-4 text-20 made-simple_descr">Create professional-looking courses with NO effort and get maximum support.</div>
      </div>
      <div className="row made-simple_list">
        {data.map((item, i) => <MadeSimpleItem key={i} idx={i} {...item} />)}
      </div>
    </section>
  );
}

export default MadeSimple;